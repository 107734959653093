"use client"

import VerifyUser from "./components/verify-user";
import { ErrorBoundary } from "../../components/error-boundary";
import { ClientErrorPage as ErrorFallback } from "../../components/error";

export default function Error({data}) {
    return (
        <ErrorBoundary fallback={<ErrorFallback/>}>
            <VerifyUser data={data}/>
        </ErrorBoundary>
    )
}