import LoadingAnimationLight from '../assets/NewLoadingAnimationLight.mp4'
import { ListGroup, Ratio } from 'react-bootstrap';

import loadingStyle from "../styles/loading-screen.module.css"
import { CompressedArticleContainer } from './container-article';

export function LoadingScreen() {
    return (
        <div className={`${loadingStyle.loading_screen} d-flex justify-content-center align-items-center`}>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div> 
    );
};

export function LongLoad({articles}) { 
    
    return (
        <div className={`d-flex flex-column align-items-center ${loadingStyle.loading_long_screen}`}>
            <div className=''>
                <div className=' text-center'>
                    <Ratio aspectRatio="16x9" className={`${loadingStyle.loading_video_ratio}`}>
                        <video src={LoadingAnimationLight} itemType='video/mp4' muted autoPlay loop playsInline></video>
                    </Ratio>
                </div>
            </div>
            <div className=' mt-2 p-5'>
                <div className=' text-center'>
                    Good things take time. Chillax.
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-2'></div>
                    <div className='col-12 col-sm-12 col-md-8'>
                        <ListGroup as="ul">
                        {articles?.recent_articles?.map((article, index) => ( 
                            <CompressedArticleContainer article={article} key={index} ancestor="loading"/>
                        ))}
                        </ListGroup>
                    </div>
                    <div className='col-md-2'></div>
                </div>
            </div>
        </div> 
    )
}
