import { Modal } from 'react-bootstrap';
import { CopyToClipboard } from './copy-to-clipboard';

export function ModalShare({isSharing, setIsSharing, QRSource, shortLink}) {
    return (
        <Modal show={isSharing} onHide={() => setIsSharing(false)}>
            <Modal.Header closeButton>
                <div className="container text-center">
                  <h3>Share</h3>
                </div>
            </Modal.Header>
          <Modal.Body>
            <div className="container text-center">
              <div className="row">
                <div className="col"></div>
                <div className="col">
                  <img src={QRSource} alt="Loading QR Code..."/>
                </div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col"></div>
                <div className="col">
                <CopyToClipboard givenText={shortLink} buttonType="outline-dark" buttonSize="sm"/>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    )
}