import React from "react";
import Error from "./error";
import { ServerErrorPage as ErrorFallback } from "../../components/error";
import { LoadingScreen } from "../../components/loading-screen";
import { useParams } from "react-router-dom";

class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            hadError: false,
            userVerifyId: null,
            errors: null
        }
    }

    componentDidMount() {
        const { id } = this.props.params;      
        this.setState({userVerifyId: id})
        this.setState({isLoading: false})
    }
    
    render() {
        return (
            <React.Fragment>
                {this?.state?.hadError
                ? <ErrorFallback errorDetails={this.state.errors}/>
                : <>{this?.state?.isLoading
                ? <LoadingScreen/>
                : <Error data={this.state}/>}</>}
            </React.Fragment>
        )
    }
}

export default (props) => (
    <Page {...props} params={useParams()}/>
)