import { Card } from "react-bootstrap"

export function ChartCard({children, headerText, subHeaderText}) {
    return (
        <Card className='dashboard-card mb-3'>
        <Card.Title className="mt-3 ms-3" as="h4">{headerText}</Card.Title>
        <p className="ms-3 card-category mb-n2">{subHeaderText}</p>
        <Card.Body  className="border-bottom-0">
            <div  className="w-100 h-100 d-flex align-items-center flex-column">
                <div className="h-100 w-100 align-content-center ct-chart" id="chartHours">
                    {children}
                </div>
                <div className="w-100 align-content-end flex-grow-1">
                    <hr ></hr>
                    <div className="stats">
                        <i className="fas fa-history"></i>
                        Updated Just Now
                    </div>
                </div>
            </div>
        </Card.Body>
    </Card>
    )
}