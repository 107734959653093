import { Button } from "react-bootstrap"
import { MdDashboard, MdAccountCircle } from "react-icons/md"

import dashboardStyle from "../../../styles/dashboard.module.css"
import { useContext, useEffect } from 'react';
import {NavbarContext} from '../../../contexts/navbar-context'

export default function SubscriberDashboard({data}) {

    const {showSearchBar, setShowSearchBar} = useContext(NavbarContext)

    useEffect(() => {
        setShowSearchBar(false);
    },[])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className={`col-2 vh-100 position-sticky top-0 border-end border-dark ${dashboardStyle.dashboard_sidebar}`}>
                    <div className="container-fluid">
                        <div className={`row pt-3 text-center border-bottom border-dark`}>
                            <h6>SUBSCRIBER DASHBOARD</h6>
                        </div>
                        <div className='row pt-3 text-center '>
                            <Button href={`${process.env.REACT_APP_CLIENT_HOST}/dashboard`} variant='dark'>
                                <MdDashboard className='me-2 mb-1'/>
                                Dashboard
                            </Button>
                        </div>
                        <div className='row pt-3 text-center'>
                            <Button href={`${process.env.REACT_APP_CLIENT_HOST}/dashboard/account-settings`} variant='outline-dark'>
                                    <MdAccountCircle className='me-2 mb-1'/>
                                    Account Settings
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                </div>
            </div>
        </div>
    )
}