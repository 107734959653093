import React, { useEffect, useState } from "react";
import error from "../assets/NewErrorImage.png"
import { Button, Image, ListGroup } from 'react-bootstrap'
import { GetTopTwentyArticles } from "../lib/fetch/fetch-articles";
import { CompressedArticleContainer } from "./container-article";

export function ClientErrorPage() {

    return (
        <div className=" vh-100 container-fluid d-flex flex-column align-items-center justify-content-center text-center">
            <div className="row img-div">
                <div className="col w-100 h-100">
                    <Image src={error} className="errors-image" fluid/>
                </div>
            </div>
            <div className="row mt-2 fw-bolder fs-5 mt-3">
                <div className="col">
                    <p>
                        We had an error while loading this page.
                    </p>
                </div>
            </div>
        </div>
    );
};

export function ServerErrorPage({errorDetails}) {
    const [articles, setArticles] = useState([{}]);
    const [hadError, setHadError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function loadTopTwenty() {
            await GetTopTwentyArticles()
            .then((res) => {
                setArticles(res.recent_articles);
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((err) => {
                setHadError(true);
            });
        }

        loadTopTwenty();
    },[])
    
    return (
        <div className=" container-fluid d-flex flex-column align-items-center justify-content-center text-center">
            <div className="row">
                <div className="col">
                    <h1>
                        {errorDetails.errorInfo.type === "failed to fetch" 
                        ? "" 
                        : errorDetails.errorInfo.type === "status code" 
                        ? "" 
                        : errorDetails.errorInfo.type === "rate limit exceeded" 
                        ? ""
                        : <></>}
                    </h1>
                </div>
            </div>
            <div className="row img-div">
                <div className="col mt-3 w-100 h-100">
                    <Image src={error} className="errors-image" fluid/>
                </div>
            </div>
            <div className="row fw-bolder fs-5 mt-3">
                <div className="col">
                    <p>
                        {errorDetails.errorInfo.type === "failed to fetch" 
                        ? "Oops, this one is a little tough. Let me run it again." 
                        : errorDetails.errorInfo.type === "status code" 
                        ? "Oops, this one is a little tough. Let me run it again."
                        : errorDetails.errorInfo.type === "rate limit exceeded" 
                        ? "Oops, this one is a little tough. Let me run it again." 
                        : <>Oops, this one is a little tough. Let me run it again.</>}
                    </p>
                </div>
            </div>
            <div className="row w-100 w-sm-100 w-md-70 w-lg-70 w-xl-70 w-xxl-70">
                <div className="col-6 text-end">
                    <Button className="" variant="outline-primary" onClick={() => {window.location.reload()}}>Retry</Button>
                </div>
                <div className="col-6 text-start">
                    <Button variant="outline-secondary" onClick={() => {window.location = process.env.REACT_APP_CLIENT_HOST}}>Home</Button>
                </div>
            </div>
            <div className="row mt-5 w-100 w-sm-100 w-md-70 w-lg-70 w-xl-70 w-xxl-70">
                <ListGroup>
                    {hadError 
                    ? <></>
                    : 
                    <>
                        {isLoading ? <></> : <>{articles?.map((article, index) => (
                            <CompressedArticleContainer key={index} article={article} ancestor={"server_error"}/>
                        ))}</>}
                    </>}
                </ListGroup>
            </div>
        </div>
    )
}