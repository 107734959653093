import { ErrorBoundary } from '../../components/error-boundary'
import { ClientErrorPage as ErrorFallback } from '../../components/error'
import AdminDashboard from './components/admin-dashboard'
import UserDashboard from './components/user-dashboard'
import SubscriberDashboard from './components/subscriber-dashboard'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/auth-context'

export default function Error({data}) {
    const {username, setUsername, email, setEmail, role, setRole} = useContext(AuthContext)

    useEffect(() => {
        setUsername(data?.user?.username);
        setEmail(data?.user?.email);
        setRole(data?.user?.role);
    },[])

    return (
        <ErrorBoundary fallback={<ErrorFallback/>}>
            {data?.user?.role === "Administrator" 
            ? <AdminDashboard data={data}/>
            : data?.user?.role === "User"
            ? <UserDashboard data={data}/>
            : data?.user?.role === "Subscriber" 
            ? <SubscriberDashboard data={data}/>
            : <></>}
        </ErrorBoundary>
    )
}