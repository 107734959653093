import { Button } from "react-bootstrap"
import { MdDashboard, MdAccountCircle } from "react-icons/md"
import { AuthContext } from "../../../contexts/auth-context"
import { useEffect, useState } from "react"

import dashboardStyle from "../../../styles/dashboard.module.css"

export function UserLevelLayout({children}) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [selected, setSelected] = useState("")

    useEffect(() => {
        window.location.href.includes("account-settings")
        ? setSelected("account-settings")
        : setSelected("dashboard")
    },[])

    const values = {
        username: username,
        setUsername: setUsername,
        email: email,
        setEmail: setEmail,
        role: role,
        setRole: setRole,
    }

    return (
        <AuthContext.Provider value={values}>
            <div className="container-fluid">
                <div className="row">
                    <div className={`d-none d-sm-none d-md-grid col-2 vh-100 position-sticky top-0 border-end border-dark ${dashboardStyle.dashboard_sidebar}`}>
                        <div className="container-fluid">
                            <div className={`row pt-3 text-center border-bottom border-dark`}>
                                <h6>{role} Dashboard</h6>
                            </div>
                            <div className='row pt-3 text-center '>
                                <Button href={`${process.env.REACT_APP_CLIENT_HOST}/dashboard`} variant={selected === "dashboard" ? "dark" : "outline-dark"}>
                                    <MdDashboard className='me-2 mb-1'/>
                                    Dashboard
                                </Button>
                            </div>
                            <div className='row pt-3 text-center'>
                                <Button href={`${process.env.REACT_APP_CLIENT_HOST}/dashboard/account-settings`} variant={selected === "account-settings" ? "dark" : "outline-dark"}>
                                    <MdAccountCircle className='me-2 mb-1'/>
                                    Account Settings
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-10">
                        {children}
                    </div>
                </div>
            </div>
        </AuthContext.Provider>
    )
}