import React from "react";
import { ErrorBoundary } from '../../components/error-boundary'
import { ClientErrorPage as ErrorFallback } from '../../components/error'
import Feed from "./components/feed";
import { Helmet } from "react-helmet";

export default function Error({data}) {
    return (
        <ErrorBoundary fallback={<ErrorFallback/>}>
            <Helmet>
                    <title>Top Trending</title>
                    <meta name="description" content="Polypinion provides A.I.-powered, decluttered, and aggregated news, separating facts from opinions on politics, technology, war, and more, to help you make informed decisions." />
                    <meta name="keywords" content="politics, technology, war, unbiased news, fact checking, news analysis, decluttered news, aggregated news" />
                    <meta name="author" content="Polypinion" />
                    <meta property="og:title" content="Top Trending" />
                    <meta property="og:description" content="Polypinion provides A.I.-powered, decluttered, and aggregated news, separating facts from opinions on politics, technology, war, and more, to help you make informed decisions." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://polypinion.com" />
            </Helmet>
            <Feed data={data}/>
        </ErrorBoundary>
    )
}