"use client"

import React from "react";
import { ErrorBoundary } from '../components/error-boundary'
import { ClientErrorPage as ErrorFallback } from '../components/error'
import Home from "./components/home";

export default function Error({data}) {
    
    return (
        <ErrorBoundary fallback={<ErrorFallback/>}>
            <Home data={data}/>
        </ErrorBoundary>
    )
}