"use client"

import React from "react";
import { ErrorBoundary } from '../../components/error-boundary'
import { ClientErrorPage as ErrorFallback } from '../../components/error'
import ParseArticle from './components/parse-article'

export default function Error({data}) {
    return (
        <ErrorBoundary fallback={<ErrorFallback/>}>
            <ParseArticle data={data}/>
        </ErrorBoundary>
    )
}