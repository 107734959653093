import React from 'react';
import { AddNewArticle, GetTopTwentyArticles } from '../../lib/fetch/fetch-articles';
import { LongLoad } from '../../components/loading-screen';
import Error from './error';
import { ServerErrorPage as ErrorFallback } from '../../components/error';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            article: null,
            recent_articles: null,
            isLoading: true,
            hadError: false,
            errors: null
        }
    }

    componentDidMount() {
        this.mostRecent();
        this.parseArticle();
    }

    mostRecent() {
        GetTopTwentyArticles()
        .then((response) => {
            this.setState({recent_articles: response})
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true})
            this.setState({errors: {errorInfo}})
        })
    }

    parseArticle() {
        const urlParam = new URLSearchParams(window.location.search).get('url');
        AddNewArticle({urlParam: urlParam})
        .then((response) => {
            // console.log(response)
            this.setState({article: response.article})
            //TODO: UNCOMMENT IN PRODUCTION
            this.setState({similar_articles: response.similar_articles})
        })
        .then(() => {
            this.setState({isLoading: false});
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}})
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.hadError 
                ? <ErrorFallback errorDetails={this.state.errors} /> 
                : <>{this.state.isLoading 
                ? <LongLoad articles={this.state.recent_articles}/> 
                : <Error data={this.state}/>}</>}
            </React.Fragment>
        )
    }
}