import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ClientErrorPage as Error } from "./components/error.jsx";
import { Maintenance } from './components/maintenance.jsx'
import Navbar from "./components/layout/navbar.jsx";
import Footer from "./components/layout/footer.jsx";

//PAGE IMPORTS
import Feed from "./pages/feed/page.jsx";
import Home from "./pages/page.jsx";
import ParseArticle from "./pages/parse-article/page.jsx";
import ExpandedArticle from "./pages/feed/expanded-article/page.jsx";
import TermsOfUse from "./pages/terms-of-use/page.jsx";
import UserDashboard from "./pages/dashboard/page.jsx";
import VerifyUser from "./pages/verify-user/page.jsx";
import AccountSettings from  "./pages/dashboard/account-settings/page.jsx";

import "./styles/globals.css"
import "./styles/bs-modifications.scss"

export default function App() {

	//TODO: write to a logger API here
	window.onerror = function(message, source, lineno, colno, error) {
		console.error("Message: ", message, "Source: ", source, "Line Number: ", lineno, "Column Number: ", colno, "Error: ", error);
		return true;
	}

	return (
		<BrowserRouter>
				{process.env.REACT_APP_MAINTENANCE_MODE === "0"
				? <Navbar><Routes>
					
						<Route path = "/" element = {<Home/>} />
						<Route path="/:id" element= {<VerifyUser/>}/>
						<Route path = "/news" element = {<Feed/>}/>
						<Route path = "/target" element = {<ParseArticle/>}/>
						<Route path="/news/:id" element = {<ExpandedArticle/>}/>
						<Route path="/terms-of-use" element = {<TermsOfUse/>}/>
						<Route path="/dashboard" element = {<UserDashboard/>}/>
						<Route path="/dashboard/account-settings" element = {<AccountSettings/>}/>
						<Route path="*" element={<Error />} />
					
				</Routes></Navbar>
				: <Routes>
					<Route path="*" element={<Maintenance />} />
				</Routes>}
			
			<div className="mt-5"></div>
			<Footer />
		</BrowserRouter>
	)
}
