import { getCookie, eraseCookie, fetcher } from './fetch-helper';

const server = process.env.REACT_APP_API_HOST;

export async function GetUserKeywords() {
	"use server"

	return (await fetcher({
		url: `${server}/api/users/get-user-keywords`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		credentials: 'include',
		invoker: 'get-user-keywords'
	}))
}

export async function GetAllKeywords() {
	"use server"

	return (await fetcher({
		url: `${server}/api/users/get-all-keywords`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		credentials: 'include',
		invoker: 'get-all-keywords'
	}))
}

export async function DeleteUserKeyword({keyword_name}) {
	"use server"

	return (await fetcher({
		url: `${server}/api/users/delete-user-keyword`,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		body: JSON.stringify({
			keyword_name: keyword_name
		}),
		credentials: 'include',
		invoker: 'delete-user-keyword'
	}))
}

export async function AddUserKeyword({keyword_name}) {
	"use server"

	return (await fetcher({
		url: `${server}/api/users/add-user-keyword`,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		body: JSON.stringify({
			keyword_name: keyword_name
		}),
		credentials: 'include',
		invoker: 'add-user-keyword'
	}))
}

export async function UpdateUserInfo({username, email}) {
	"use server"
	
	return (await fetcher({
		url: `${server}/api/users/update-user`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		body: JSON.stringify({
			username: username,
			email: email
		}),
		credentials: 'include',
		invoker: 'update-user'
	}))
}

export async function IsSignedIn() {
	"use server"

	const res = (await fetcher({
		url: `${server}/api/users/is-signed-in`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		credentials: 'include',
		returnJson: false,
		invoker: 'is-signed-in'
	}))

	if(res.status === 401) return {
		signedIn: false,
	};

	return {
		signedIn: true,
		user: (await res.json())
	};
}

export async function ValidateUser({email, username}) {
  	"use server"

  	const res = (await fetcher({
		url: `${server}/api/users/validate-user`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			username: username,
			email: email
		}),
		returnJson: true,
		invoker: 'validate-user'
	}).catch((err) => {
	}))

    return res;
}

export async function VerifyUser({id, code}) {
  	"use server"

	return (await fetcher({
		url: `${server}/api/users/verify`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			id: id,
			code: code
		}),
		credentials: 'include',
		invoker: 'verify'
	}))
}

export async function SignOut() {
	"use server"

	const res = (await fetcher({
		url: `${server}/api/users/sign-out`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		credentials: 'include',
		invoker: 'sign-out'
	}))

	eraseCookie("csrf_token");

	return res;
}

export async function SignIn({email}) {
	"use server"

	return (await fetcher({
		url: `${server}/api/users/sign-in`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			email: email
		}),
		invoker: 'sign-in'
	}))
}

export async function GetSubscriberLocations() {
	"use server"

	return (await fetcher({
		url: `${server}/api/metrics/get-subscriber-locations`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		body: JSON.stringify({
			cities: [
				"Richmond",
				"San Diego",
				"Tijuana"
			]
		}),
		credentials: 'include',
		invoker: 'get-subscriber-locations'
	}))
}

export async function GetNumUsers() {
    "use server"

	return (await fetcher({
		url: `${server}/api/metrics/num-users`, 
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		invoker: 'num-users'
	}))
}

export async function AddUser({username, email, city}) {
    "use server"

	return (await fetcher({
		url: `${server}/api/users/register`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			username: username,
			email: email,
			city: city
		}),
		invoker: 'register'
	}))
}