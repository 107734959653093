import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { NavbarContext } from '../../contexts/navbar-context';
import { Desktop, Mobile } from './MobileDesktop';

export default function Home({data}) {
	const [url, setUrl] = useState('');

	const {showSearchBar, setShowSearchBar} = useContext(NavbarContext)

	useEffect(() => {
		setShowSearchBar(false);
	},[])

	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (url) {
			navigate(`/target?url=${url}`);
		} else {
			console.error("Provide URL");
		}
	};

	const handleInputChange = (event) => {
		setUrl(event.target.value);
	};

	return (
		<React.Fragment>
			<div className='container-fluid d-none d-sm-none d-md-grid d-lg-grid d-xl-grid d-xxl-grid'>
				<Desktop handleSubmit={handleSubmit} url={url} handleInputChange={handleInputChange} data={data}/>
			</div>
			<div className='container-fluid d-grid d-sm-grid d-md-none d-lg-none d-xl-none d-xxl-none'>
				<Mobile handleSubmit={handleSubmit} url={url} handleInputChange={handleInputChange} data={data}/>
			</div>
		</React.Fragment>
	);
};
