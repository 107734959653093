import { useEffect, useState } from "react"
import { Button, ButtonGroup, Form, InputGroup, Modal } from "react-bootstrap"
import { AddUserKeyword, DeleteUserKeyword, UpdateUserInfo } from "../../../../lib/fetch/fetch-users"
import { AlertValidation } from "../../../../components/alert-validation"
import { RxCross1 } from "react-icons/rx";

import dashboardStyle from "../../../../styles/dashboard.module.css"

var RESET_INFO = {
    username: "",
    email: ""
}

export default function Settings({data, handler}) {

    const [showResetForm, setShowResetForm] = useState(false);
    const [resetInfo, setResetInfo] = useState(RESET_INFO);
    const [errors, setErrors] = useState({});
    const [hadError, setHadError] = useState(false);
    const [keyword, setKeyword] = useState("");

    function updateInfo(fields) {
        setResetInfo(prev => {
            return {...prev, ...fields};
        })
    }

    useEffect(() => {
        if(!hadError) setErrors(RESET_INFO);
    },[hadError])

    async function submitUpdates() {
        //temporary validator until I have time to move it to validators file
        if(resetInfo.email === "" || resetInfo.username === "") {
            setErrors({"username": "Invalid Username or Password Provided"});
            setHadError(true);
        } else {
            await UpdateUserInfo({username: resetInfo.username, email: resetInfo.email})
            .catch((err) => {
                setHadError(true);
            });
            setResetInfo(RESET_INFO);
            setErrors({});
            setShowResetForm(false);
        }
    }

    async function submitKeyword() {
        await AddUserKeyword({keyword_name: keyword})
        .catch((err) => {
            setHadError(true);
        })
        setKeyword("");
        setErrors({});
        handler();

    }

    async function removeKeyword({keyword_name}) {
        await DeleteUserKeyword({keyword_name: keyword_name})
        .catch((err) => {
            setHadError(true);
        })
        setKeyword("");
        setErrors({});
        handler();
    }

    return (
        <div className="container-fluid p-0">
        <Modal show={showResetForm} onHide={() => {setShowResetForm(false); setErrors({}); setResetInfo(RESET_INFO)}}>
            <Modal.Header>
                <div className="container-fluid text-center">
                    <h3>Reset Username/Email</h3>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid p-2">

                    {hadError 
                    ? <AlertValidation errors={errors} setHadError={setHadError} setErrors={setErrors}/>
                    : <></>}
                    <div className="row mt-1">
                        <Form.Control onChange={e => updateInfo({username: e.target.value})} value={resetInfo.username} placeholder="New Username"/>
                    </div>
                    <div className="row mt-3">
                        <Form.Control onChange={e => updateInfo({email: e.target.value})} value={resetInfo.email} placeholder="New Email"/>
                    </div>
                    <div className="row mt-4">
                        <Button variant="outline-dark" onClick={() => submitUpdates()}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
            <div className="row ">
                <div className="col-12 col-sm-12 col-md-12 p-0">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col text-start ms-2">
                                <Button onClick={() => setShowResetForm(true)} className="mt-3">
                                    Reset Username/Email
                                </Button>
                            </div>
                        </div>
                        <div className="row ms-1 mt-5">
                            <h2 className="text-decoration-underline fw-bolder">Keywords</h2>
                        </div>
                        <div className="p-0 ms-1 row mt-3 w-xs-100 w-sm-100 w-md-30 w-lg-30 w-xl-30 w-xxl-30">
                            <InputGroup className="w-100">
                                <Form.Control className="w-75" value={keyword} onChange={e => setKeyword(e.target.value)} placeholder="Add Keyword"/>
                                <Button onClick={() => submitKeyword()} className="w-25">Submit</Button>
                            </InputGroup>
                        </div>
                        <div className="row mt-3 p-3">
                            <div className="col-12 col-sm-12 col-md-6 p-0">
                                {data.keywords?.map((keyword, index) => (
                                <ButtonGroup key={index} className="w-40 w-sm-40 w-md-30 ms-1 mt-3">
                                    <Button variant="primary" className="w-75 text-start pe-none">
                                        {keyword?.keyword_name?.length >= 10 
                                        ? <>{keyword?.keyword_name?.substring(0, 10)}...</> 
                                        : keyword?.keyword_name}
                                    </Button>
                                    <Button onClick={() => removeKeyword({keyword_name: keyword.keyword_name})} variant="secondary" className="w-25 text-center p-1">
                                        <RxCross1 fontSize={15}/>
                                    </Button>
                                </ButtonGroup>
                                ))}
                            </div>
                            <div className="col-12 col-sm-12 col-md-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}