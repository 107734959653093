import { Chart as ChartJS, 
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip as ChartTooltip,
    Legend, 
    BarElement,
    ArcElement} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2'
import { ChartCard } from './ChartCard';
import { useContext, useEffect } from 'react';
import { NavbarContext } from '../../../contexts/navbar-context'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import dashboardStyle from "../../../styles/dashboard.module.css"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    ChartTooltip,
    Legend,
)

export default function AdminDashboard({data}) {

    const {showSearchBar, setShowSearchBar} = useContext(NavbarContext)

    useEffect(() => {
        setShowSearchBar(false);
    },[])

    const labels = ['Richmond', 'San Diego', 'Tijuana'];
    const hourLabels = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                        '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']

    const hourlyViewsData = {
        labels: hourLabels,
        datasets: [
            {
                label: "Visitors per Hour",
                data: hourLabels.map((hourLabel, index) => Number(data["metrics"][2]["hourly_visits"][hourLabel.substring(0, 2)] ?? "0")),
                borderColor: 'red',
                backgroundColor: 'red'
            },
            {
                label: "Subscribers per Hour",
                data: hourLabels.map((hourLabel, index) => Number(data["metrics"][3]["hourly_subscribers"][hourLabel.substring(0, 2)] ?? "0")),
                borderColor: 'green',
                backgroundColor: 'green'
            }
        ]
    }
    
    const subscriberData = {
        labels,
        datasets: [
            {
                label: "Total Visitors",
                data: [data["subscriber_proportion"]["subscriber_cities"]["Richmond"], data["subscriber_proportion"]["subscriber_cities"]["San Diego"], data["subscriber_proportion"]["subscriber_cities"]["Tijuana"]],
                borderColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)'
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderWidth: 1
            }
        ]
    }

    const totalVisitorsData = {
        labels,
        datasets: [
            {
                label: "Total Visitors",
                data: [data["metrics"][0]["total_visits"]["Richmond"], data["metrics"][0]["total_visits"]["San Diego"], data["metrics"][0]["total_visits"]["Tijuana"]],
                borderColor: 'red',
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }
        ]
    }

    const articleVisitorsData = {
        labels,
        datasets: [
            {
                label: "Total Views",
                data: [data["metrics"][1]["article_visits"]["Richmond"], data["metrics"][1]["article_visits"]["San Diego"], data["metrics"][1]["article_visits"]["Tijuana"]],
                borderColor: 'blue',
                backgroundColor: 'lightblue'
            }
        ]

    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12'>
                    <div className='container-fluid mt-4'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <ChartCard headerText={"User Locations"} subHeaderText={"Overall Performance"}>
                                    <Bar plugins={[ChartDataLabels]} options={{
                                    responsive: true,
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 5000,
                                            ticks: {
                                                stepSize: 200
                                            }
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            position: 'top'
                                        },
                                        title: {
                                            display: true,
                                            text: 'Visitation by Locale'
                                        }
                                    }
                                    }} data={totalVisitorsData}/>
                                </ChartCard>
                            </div>
                            <div className='col-md-6'>
                                <ChartCard headerText={"Article Viewership"} subHeaderText={"Top Locations for Article Viewership"}>
                                    <Bar plugins={[ChartDataLabels]} options={{
                                    responsive: true,
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 500,
                                            ticks: {
                                                stepSize: 100
                                            }
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            position: 'top'
                                        },
                                        title: {
                                            display: true,
                                            text: 'Visitation by Locale'
                                        }
                                    }
                                    }} data={articleVisitorsData}/>
                                </ChartCard>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <ChartCard headerText={"Subscriber Locations"} subHeaderText={"Subscribers by Location"}>
                                    <Doughnut plugins={[ChartDataLabels]} options={{
                                    plugins: {
                                        legend: {
                                            onHover: (evt, item, legend) => {
                                            const chart = legend.chart;
                                            const tooltip = chart.tooltip;

                                            const chartArea = chart.chartArea;
                                            tooltip.setActiveElements([{
                                                datasetIndex: 0,
                                                index: item.index,
                                            }], {
                                                x: (chartArea.left + chartArea.right) / 2,
                                                y: (chartArea.top + chartArea.bottom) / 2,
                                            });


                                            chart.update();
                                            },
                                        }
                                    },
                                    responsive: true, 
                                    maintainAspectRatio: false}} data={subscriberData}/>
                                </ChartCard>
                            </div>
                            <div className='col-md-6'>
                                <ChartCard headerText={"Viewership Over Time"} subHeaderText={"Yesterday's Viewership Trends"}>
                                    <Line options={{
                                        responsive: 'true',
                                        scales: {
                                            y: {
                                                min: 0,
                                                max: 500,
                                                ticks: {
                                                    stepSize: 100
                                                }
                                            }
                                        },
                                        plugins: {
                                            legend: {
                                                position: 'top'
                                            },
                                            title: {
                                                display: true,
                                                text: 'Viewership Over Time'
                                            }
                                        }
                                    }} data={hourlyViewsData}/>
                                </ChartCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}