import React, { useEffect, useState } from 'react';
import { Navbar as NavbarBS, Nav, Container, 
	NavDropdown, Form, Button, 
	Image} from 'react-bootstrap';
import NavLogo from "../../assets/Logo192.png";
import LogoText from '../../assets/LogoTextNew.png'
import { useNavigate } from 'react-router-dom';
import { 
	MdOutlineHome as HomeOutlinedIcon, 
	MdOutlineSubscriptions as SubscriptionsOutlinedIcon, 
	MdOutlineTrendingUp as TrendingUpOutlinedIcon,
	MdLogout, MdAccountCircle,
	MdDashboard
} 
	from "react-icons/md";
import { ImMenu } from "react-icons/im";

import { AddUser, GetNumUsers, SignIn, SignOut, IsSignedIn } from '../../lib/fetch/fetch-users'
import { validateSubscription, validateSignIn } from '../../lib/validate/validate';
import { ModalForm } from '../modal-form';
import { ToastConfirmation } from '../toast-confirmation';
import { ServerErrorPage as ErrorFallback } from '../error'
import { ErrorBoundary } from '../error-boundary'
import { ClientErrorPage as ClientErrorFallback } from '../error';
import { NavbarContext } from '../../contexts/navbar-context'


import navbarStyle from "../../styles/navbar.module.css"; 

var STYLES = {
	home_color_bg: "",
	top_100_color_bg: "",
	subscribe_color_bg: "",
	signout_color_bg: "",
	dashboard_color_bg: "",
	dropdown_home_color_bg: "whitesmoke",
	dropdown_top_100_color_bg: "whitesmoke",

	home_color_txt: "black",
	top_100_color_txt: "black",
	subscribe_color_txt: 'black',
	signout_color_txt: "black",
	dashboard_color_txt: "black",
	dropdown_home_color_txt: "black",
	dropdown_top_100_color_txt: "black",
}

var USER = {
	email: "",
	username: "",
	city: "",
}

export default function Navbar({children}) {
	const [styles, setStyles] = useState(STYLES);
	const [user, setUser] = useState(USER);
	const [showSubscribe, setShowSubscribe] = useState(false)
	const [subscribing, setSubscribing] = useState(true)
	const [counts, setCounts] = useState({numSubs: "", numVisitors: ""})
	const [subscribeErrors, setSubscribeErrors] = useState({});
	const [hadUserError, setHadUserError] = useState(false);
	const [showSubmit, setShowSubmit] = useState(false);
	const [showSignIn, setShowSignIn] = useState(false)
	const [hadError, setHadError] = useState(false);
	const [errors, setErrors] = useState({});
	const [signedIn, setSignedIn] = useState(false);
	const [showSearchBar, setShowSearchBar] = useState(false);
	const [url, setUrl] = useState('');
  
	const navigate = useNavigate();
  
	const handleSubmit = async () => {
	  if (url) {
		window.location = `/target?url=${url}`;
	  } else {
		console.error("Provide URL");
	  }
	};
  
	const handleInputChange = (value) => {
	  setUrl(value);
	};

	function checkWindow() {
		if(window.location.href === `${process.env.REACT_APP_CLIENT_HOST}/` || window.location.href === `${process.env.REACT_APP_CLIENT_HOST}/#`) {
			updateStyles({home_color_bg: "black", home_color_txt: "white"})
			updateStyles({dropdown_home_color_bg: "black", dropdown_home_color_txt: "white"})
		} else if(window.location.href === `${process.env.REACT_APP_CLIENT_HOST}/news`) {
			updateStyles({top_100_color_bg: "black", top_100_color_txt: "white"})
			updateStyles({dropdown_top_100_color_bg: "black", dropdown_top_100_color_txt: "white"})
		} else if(window.location.href === `${process.env.REACT_APP_CLIENT_HOST}/subscribe`) {
			updateStyles({subscribe_color_bg: "black", subscribe_color_txt: "white"})
		}
	}

	useEffect(() => {
		if(!showSubscribe) {
			updateStyles({subscribe_color_bg: "", subscribe_color_txt: "black"})
			updateUser({...USER})
			setSubscribing(true)
			setHadUserError(false)
			setSubscribeErrors({});
			checkWindow();
		}
		
	},[showSubscribe])

	useEffect(() => {
		async function checkSignedIn() {
			await IsSignedIn()
			.catch((err) => {})
			.then((res) => {
				if(res) setSignedIn(res.signedIn);
			})
		}

		getCounts();
		checkWindow();
		checkSignedIn();
	},[])

	async function getCounts() {

		try {
			const res = await GetNumUsers()
			setCounts({numSubs: res[0].subbed, numVisitors: res[1].visited});
		} catch(err) {
			setHadError(true);
			let errorInfo = err.getErrInfo();
			setErrors({errorInfo});
		}
	}

	async function submitUser() {
		const m_errors = await validateSubscription(user);
	
		if(!m_errors.username && !m_errors.email) {
			setShowSubscribe(false)
			setShowSubmit(true);
			try {
				await AddUser({username: user.username, city: user.city, email: user.email})
			} catch(err) {
				setHadError(true);
				let errorInfo = err.getErrInfo();
				setErrors({errorInfo});
			}
			updateUser({...USER})
		} else {
			setSubscribeErrors(m_errors);
			setHadUserError(true);
		}
	}

	async function loginUser() {
		const m_errors = await validateSignIn(user);

		if(!m_errors.email) {
			setShowSubscribe(false)
			setShowSignIn(true)
			try {
				await SignIn({email: user.email});
			} catch(err) {
				setHadError(true);
				let errorInfo = err?.getErrInfo();
				setErrors({errorInfo});
			}
			updateUser({...USER})
			setSubscribing(true)
		} else {
			setSubscribeErrors(m_errors);
			setHadUserError(true);
		}
	}

	async function logoutUser() {
		await SignOut();
		window.location = process.env.REACT_APP_CLIENT_HOST;
		checkWindow();
	}

	function updateStyles(n_styles) {
		setStyles(prev => {
			return {...prev, ...n_styles};
		})
	}

	function updateUser(fields) {
		setUser(prev => {
			return {...prev, ...fields};
		})
		checkWindow();
	}

	const handleLogoClick = () => {
		updateStyles({...STYLES})
		updateStyles({home_color_bg: "black", home_color_txt: "white"})
		updateStyles({dropdown_home_color_bg: 'black', dropdown_home_color_txt: "white"})
		navigate('/')
	};

	const handleFactsClick = () => {
		updateStyles({...STYLES})
		updateStyles({top_100_color_bg: 'black', top_100_color_txt: "white"})
		updateStyles({dropdown_top_100_color_bg: 'black', dropdown_top_100_color_txt: "white"})
		navigate('/news')
	};
	
	const handleSubscribeClick = () => {
		updateStyles({...STYLES})
		updateStyles({subscribe_color_bg: 'black', subscribe_color_txt: "white"})
		setShowSubscribe(true)
	};

	const handleSignOutClick = () => {
		updateStyles({...STYLES})
		updateStyles({signout_color_bg: 'black', signout_color_txt: "white"});
		logoutUser();
	}

	const handleDashboardClick = () => {
		updateStyles({...STYLES})
		updateStyles({dashboard_color_bg: 'black', dashboard_color_txt: "white"});
		window.location = process.env.REACT_APP_CLIENT_HOST + "/dashboard"
	}

	const navbarData = {
		showSearchBar: showSearchBar,
		setShowSearchBar: setShowSearchBar,
	}

	return (
		
		<NavbarContext.Provider value={navbarData}>
			<ErrorBoundary fallback={<ClientErrorFallback/>}>
				{hadError 
				? <ErrorFallback errorDetails={errors}/> 
				: 
				<>
				<NavbarBS className={`${navbarStyle.layout_navbar}`} bg="light" data-bs-theme="light">
					<ModalForm formType={"Sign Up"} toShow={showSubscribe && subscribing} toHide={setShowSubscribe} 
					footerText={`Join ${counts.numSubs} Other Subscribers. Or Browse Alongside ${counts.numVisitors} Others`}
					hadError={hadUserError} errors={subscribeErrors} setHadError={setHadUserError} setErrors={setSubscribeErrors}>
						<Form.Control className={'mt-4'} id='email-input' onChange={e => updateUser({email: e.target.value})} value={user.email} placeholder='Email'/>
						<Form.Control className={'mt-3'} id='username-input' onChange={e => updateUser({username: e.target.value})} value={user.username} placeholder='Username'/>

						{/* TODO: make this a selector with a fixed amount of cities as per an external library */}
						<Form.Control className={'mt-3'} onChange={e => updateUser({city: e.target.value})} value={user.city} placeholder='City'/>
						<Button data-testid="sub-button" className={'mt-3 w-75'} onClick={() => submitUser()} variant='outline-dark'>Sign Up</Button>
						<h6 className={'mt-3 text-secondary'}>Returning User? <a href="#" onClick={() => {updateUser({...USER}); setSubscribing(false); setHadUserError(false); setSubscribeErrors(false)}}>Click Here</a></h6>
					</ModalForm>

					<ModalForm formType={"Sign In"} toShow={showSubscribe && !subscribing} toHide={setShowSubscribe}
					footerText={`Join ${counts.numSubs} Other Subscribers. Or Browse Alongside ${counts.numVisitors} Others`}
					hadError={hadUserError} errors={subscribeErrors} setHadError={setHadUserError} setErrors={setSubscribeErrors}>
						<Form.Control className={'mt-4'} id='email-input' onChange={e => updateUser({email: e.target.value})} value={user.email} placeholder='Enter Email'/>
						<Button className={'mt-3 w-75'} onClick={() => loginUser()} variant='outline-dark'>Sign In</Button>
						<h6 className={'mt-3 text-secondary'}>New User? <a href="#" onClick={() => {updateUser({...USER}); setSubscribing(true); setHadUserError(false); setSubscribeErrors(false)}}>Click Here</a></h6>
					</ModalForm>

					<ToastConfirmation show={showSubmit} setShow={setShowSubmit} bodyText={"Congratulations, you succesfully subscribed! Please check your email for further instructions."}/>
					<ToastConfirmation show={showSignIn} setShow={setShowSignIn} bodyText={"Please check your email to verify your sign in."}/>
					
					<Container fluid>
							<div>
								<a href='#' onClick={() => handleLogoClick()}>
								<div className={'container-fluid'}>
									<div className='row'>
										<div className={'col mt-lg-1 d-none d-sm-grid'}>
											<Image src={NavLogo} height={40} width={40} alt="Logo" />
										</div>
										<div className={'col  d-grid d-sm-none'}>
											<Image src={LogoText} alt='LogoText' className={`${navbarStyle.navbar_web_logo_txt} mt-3`} height={32} fluid/>
										</div>
									</div>
								</div>  
								</a>
							</div>
								
							<div className='d-none d-sm-none d-md-none d-lg-flex flex-row w-50'>
								<div className='w-75 mt-2 me-2'>
									{showSearchBar 
									? 
									<>
										<Form.Control value={url} onChange={e => handleInputChange(e.target.value)}/>
									</>
									: <></>}
								</div>
								<div className='w-25 '>
									{showSearchBar 
									? <Button onClick={() => handleSubmit()} className={`h-75 mt-2 ${navbarStyle.navbar_discover_button}`} size='sm' variant='outline-dark' type='submit'>
										Discover Facts
										</Button>
									: <></>}
								</div>
							</div>
							<div>
								<Nav className={"me-auto"}>
									<div className={`${navbarStyle.navbar_links_menu} d-none d-sm-flex`}>
										<Nav.Link style={{color: styles["home_color_txt"], backgroundColor: styles["home_color_bg"]}} onClick={handleLogoClick}     
										className={`${navbarStyle.navbar_option} fw-bold ms-2 rounded ${navbarStyle.navbar_home}`}>
											<div className={'container-fluid mt-1'}>
												<HomeOutlinedIcon fontSize={25} className={'mb-1 me-1'}/>
												Home
											</div>
										</Nav.Link>

										<Nav.Link style={{color: styles["top_100_color_txt"], backgroundColor: styles["top_100_color_bg"]}} onClick={handleFactsClick}     
										className={`${navbarStyle.navbar_option} fw-bold ms-2  rounded ${navbarStyle.navbar_top_100}`}>
											<div className={'container-fluid mt-1'}>
												<TrendingUpOutlinedIcon fontSize={25} className={'mb-1 me-1'}/>
												Top 100
											</div>
										</Nav.Link>
										
										<NavDropdown align="end" title={<span className={`text-dark ms-2 ${navbarStyle.navbar_dropdown_title}`}><MdAccountCircle fontSize={25} className={'me-1'}/>Account</span>} className={`d-flex align-items-center ${navbarStyle.navbar_account_dropdown} text-dark fw-bold rounded justify-content-center pb-2`}>
										{!signedIn ? <NavDropdown.Item data-testid="subscribe-button" style={{color: styles["subscribe_color_txt"], backgroundColor: styles["subscribe_color_bg"]}} onClick={handleSubscribeClick} 
											className={`${navbarStyle.navbar_option} ${navbarStyle.navbar_subscribe}`}>
												<SubscriptionsOutlinedIcon fontSize={25} className={'mb-1 me-1'}/>
												Sign Up
											</NavDropdown.Item> : <></>}
											{signedIn ? <NavDropdown.Item data-testid="subscribe-button" style={{color: styles["signout_color_txt"], backgroundColor: styles["signout_color_bg"]}} onClick={handleSignOutClick} 
											className={`${navbarStyle.navbar_option} ${navbarStyle.navbar_signout}`}>
												<MdLogout fontSize={25} className={'mb-1 me-1'}/>
												Sign Out
											</NavDropdown.Item> : <></>}
											{signedIn ? <NavDropdown.Item data-testid="subscribe-button" style={{color: styles["signout_color_txt"], backgroundColor: styles["signout_color_bg"]}} onClick={handleDashboardClick} 
											className={`${navbarStyle.navbar_option} ${navbarStyle.navbar_signout}`}>
												<MdDashboard fontSize={25} className={'mb-1 me-1'}/>
												Dashboard
											</NavDropdown.Item> : <></>}
										</NavDropdown>
									</div>
									<div className={" d-grid d-sm-none"}>
										<NavDropdown className={`${navbarStyle.navbar_links_dropdown}`} align="end" title={<ImMenu color='black' fontSize={35}/>} id="basic-nav-dropdown">
											<NavDropdown.Item style={{color: styles["dropdown_home_color_txt"], backgroundColor: styles["dropdown_home_color_bg"]}} onClick={handleLogoClick} className={`${navbarStyle.navbar_option} ${navbarStyle.navbar_home}`}>Home</NavDropdown.Item>
											<NavDropdown.Item style={{color: styles["dropdown_top_100_color_txt"], backgroundColor: styles["dropdown_top_100_color_bg"]}} onClick={handleFactsClick} className={`${navbarStyle.navbar_option} ${navbarStyle.navbar_top_100}`}>Top 100</NavDropdown.Item>
										</NavDropdown>
									</div>
								</Nav>
							</div>
					</Container>
				</NavbarBS>
			{children}
			</>}
			</ErrorBoundary>
		</NavbarContext.Provider>
	);
}
