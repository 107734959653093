import { Card } from "react-bootstrap";
import { useContext, useEffect, useState } from 'react';
import { NavbarContext } from '../../../contexts/navbar-context'
import { VerifyUser as VerifyUserAction } from '../../../lib/fetch/fetch-users'

import verifyStyle from "../../../styles/verify-user.module.css"
import { ServerErrorPage } from "../../../components/error";

export default function VerifyUser({data}) {

    const {showSearchBar, setShowSearchBar} = useContext(NavbarContext)

    const [input, setInput] = useState("");
    const [errors, setErrors] = useState(null);

    useEffect(() => {
      setShowSearchBar(false);
    },[])

    useEffect(() => {
        if(input.length === 6) {
            VerifyUserAction({id: data.userVerifyId, code: input})
            .then((response) => {
                document.cookie = "csrf_token=" + response[1]["csrf_token"]
                window.location = process.env.REACT_APP_CLIENT_HOST;
            })
            .catch((err) => {
                let errorInfo = err.getErrInfo();
                setErrors({errorInfo});
            })
        }
    },[input])

    return (
        <div className={`container-fluid d-flex align-items-center justify-content-center ${verifyStyle.verify_user_div}`}>
        {errors ? <ServerErrorPage/> : 
            <div className="row flex-grow-1">
                <div className="col-md-4"></div>
                <div className="col-12 col-md-4">
                    <Card>
                        <Card.Header className="d-flex justify-content-center">
                            <h2 className="fw-bolder">
                                Verification Code
                            </h2>
                        </Card.Header>
                        <Card.Body>
                            <div className="container-fluid">
                                <div className="row text-center">
                                    <div className="col-1"></div>
                                    <div className="col-10">
                                        <input maxLength={6} onChange={e => setInput(e.target.value)} value={input} className="rounded-5 text-center"/>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4"></div>
            </div>}
        </div>
    )
}