import React from 'react';
import { GetArticleByID } from '../../../lib/fetch/fetch-articles';
import { LoadingScreen } from '../../../components/loading-screen';
import Error from './error';
import { ServerErrorPage as ErrorFallback } from '../../../components/error';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class PageState extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            article: null,
            similar_articles: null,
            isLoading: true,
            hadError: false,
            errors: null
        }
    }

    componentDidMount() {
        const { id } = this.props.params; 
        this.retrieveData(id);
    }

    retrieveData(id) {
        GetArticleByID({id})
        .then((response) => {
            // this.setState({article: response.articles})

            //TODO: Uncomment In Production
            this.setState({article: response[0].articles});
            this.setState({similar_articles: response[1].similar_articles})
            
        })
        .then(() => {
            this.setState({isLoading: false});
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}})
        })
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                        <title>{this.state.article?.title}</title>
                        <meta name="description" content={this.state.article?.facts?.substring(0, 100)}/>
                        <meta name="keywords" content="news, unbiased news, fact checking, news analysis, decluttered news, aggregated news" />
                        <meta name="author" content="Polypinion" />
                        <meta property="og:title" content={this.state.article?.title} />
                        <meta property="og:description" content={this.state.article?.facts?.substring(0, 100)} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`https://polypinion.com/news/${this.state.article?.article_id}`} />
                </Helmet>
                {this.state.hadError 
                ? <ErrorFallback errorDetails={this.state.errors}/> 
                : <>{this.state.isLoading 
                ? <LoadingScreen/> 
                : <Error data={this.state}/>}</>}
            </React.Fragment>
        )
    }
}

export default (props) => (
    <PageState {...props} params={useParams()}/>
)