import { ToastContainer, Toast } from 'react-bootstrap'

export function ToastConfirmation({show, setShow, bodyText}) {
    return (
        <ToastContainer className='mt-5 pt-4 pe-4 navbar-toast' position='top-end'>
            <Toast show={show} onClose={() => setShow(false)}>
                <Toast.Header>
                    <strong className="me-auto">Polypinion</strong>
                    <small>Just Now</small>
                </Toast.Header>
                <Toast.Body>{bodyText}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}