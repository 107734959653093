import React from 'react';
import { LoadingScreen } from '../../components/loading-screen';
import Error from './error';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({isLoading: false});
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isLoading 
                ? <LoadingScreen/> 
                : <Error data={this.state}/>}
            </React.Fragment>
        )
    }
}