import { ServerError } from "../../components/server-error";

const server = process.env.REACT_APP_API_HOST;
const isgd = `https://is.gd`

export async function ValidateIFrameURL({url}) {

	const errors = {};

	const res = await fetch(url)
	.catch((err) => {
		console.error(err);
		errors.msg = err;
		errors.hadError = true;
		errors.type = "failed to fetch";
	});

	if(errors.hadError) throw new ServerError(errors);

	if(res.status !== 200) {
		console.error("Error While Validating <iframe/> Tag Source");
		errors.msg = res.statusText;
		errors.hadError = true;
		errors.type = "status code";
		errors.code = res.status;
	}

    if(res.hadError) throw new ServerError(errors);

    if(res.headers['X-Frame-Options'] === 'SAMEORIGIN') throw new ServerError({
		msg: "Source website does not allow cross-origin framing.",
		hadError: true,
		type: "iframe not supported",
		code: 401
    });
}

//TODO: need to test if the tinyurl expiry conditions actually work
export async function GenerateTinyURL({url, sortedarticle_id}) {

    const errors = {};

    let timeout_end = localStorage.getItem("tinyrate-exceeded");

	const savedTinyurl = await fetch(`${server}/api/articles/check-tinyurl`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			sortedarticle_id: sortedarticle_id
		})
	})
	.catch((err) => {
		console.error(err);
		errors.msg = err;
		errors.hadError = true;
		errors.type = "failed to fetch"
	})

	if(savedTinyurl.status !== 400) {
		return (await savedTinyurl.json());
	}

    if(!timeout_end || (timeout_end && Number(timeout_end) <= Date.now())) {
		var res = await fetch(`${isgd}/create.php?format=json&url=${encodeURIComponent(url)}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
		.catch((err) => {
			console.error(err);
			errors.msg = err;
			errors.hadError = true;
			errors.type = "failed to fetch"
		});

		localStorage.removeItem("tinyrate-exceeded");

		if(res.status === 502) {
			console.error("TinyURL Rate Limit Exceeded While Fetching at \"GenerateTinyURL\": ", res.statusText);
			errors.msg = res.statusText;
			errors.hadError = true;
			errors.type = "rate limit exceeded"
			errors.code = res.status;
	
			let timeout = String(Date().now() + 600000);
			localStorage.setItem("tinyrate-exceeded", timeout);
		}
		
		if(res.status === 400 || res.status === 406 || res.status === 503) {
			console.error("Error While Fetching at \"GenerateTinyURL\": ", res.statusText);
			errors.msg = res.statusText;
			errors.hadError = true;
			errors.type = "status code"
			errors.code = res.status;
		}

		if(errors.hadError) throw new ServerError(errors);

		var fetched_url = (await res.json())?.shorturl;

		await fetch(`${server}/api/articles/save-tinyurl`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				url: fetched_url,
				sortedarticle_id: sortedarticle_id
			})
		})
		.catch((err) => {
			console.error(err);
			errors.msg = err;
			errors.hadError = true;
			errors.type = "failed to fetch"
		})

		if(errors.hadError) throw new ServerError(errors);

    } else {
		errors.msg = "tinyURL requests are currently on timeout.";
		errors.hadError = true;
		errors.type = "rate limit exceeded";
	}

    if(errors.hadError) throw new ServerError(errors);

    return {
		"shorturl": fetched_url
	};
}