import { Modal } from "react-bootstrap";
import { AlertValidation } from "./alert-validation";

export function ModalForm({formType, toShow, toHide, footerText, children,
                           hadError, errors, setHadError, setErrors}) {
    return (
        <Modal show={toShow} onHide={() => toHide(false)} animation={false}>
            <Modal.Body>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h1>{formType}</h1>
                            <h6 className='text-secondary'>Make Better Decisions</h6>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10 text-center'>
                            {hadError ? 
                            <AlertValidation errors={errors} setHadError={setHadError} setErrors={setErrors}/>
                            : <></>}
                            {children}
                        </div>
                        <div className='col-1'></div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='container-fluid text-center'>
                    <h6 className='navbar-user-count text-secondary'>{footerText}</h6>
                </div>
            </Modal.Footer>
        </Modal>
    )
}