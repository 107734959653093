import React from 'react'
import { ServerErrorPage as ErrorFallback } from '../../components/error'
import { LoadingScreen } from '../../components/loading-screen'
import Error from './error'
import { GetAdminDashboardMetrics } from '../../lib/fetch/fetch-articles';
import { GetSubscriberLocations, IsSignedIn } from '../../lib/fetch/fetch-users';
import { UserLevelLayout } from './components/user-level-layout'

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hadError: false,
            isLoading: true,
            user: null,
            errors: null,
            metrics: null,
            subscriber_proportion: null
        };
    }

    componentDidMount() {
        this.fetchSignIn()
        this.fetchSubscriberMetrics();
        this.fetchMetrics();
    }

    async fetchSignIn() {
        await IsSignedIn()
        .then((response) => {
            this.setState({user: response.user.user})
            return response.user.user;
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}});
        })
    }

    fetchSubscriberMetrics() {
        GetSubscriberLocations()
        .then((response) => {
            this.setState({subscriber_proportion: response})
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}})
        })
    }

    fetchMetrics() {
        GetAdminDashboardMetrics()
        .then((response) => {
            this.setState({metrics: response})
        })
        .then(() => {
            this.setState({isLoading: false});
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}})
        })
    }

    render() {
        return (
            <UserLevelLayout>
                {this.state.hadError 
                ? <ErrorFallback errorDetails={this.state.errors}/> 
                : this.state.isLoading 
                ?  <LoadingScreen/> 
                : <Error data={this.state}/>}
            </UserLevelLayout>
        )
    }
}