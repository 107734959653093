

export class ServerError extends Error {
    constructor(errorDict) {
        super(errorDict.msg);
        this.name = "ServerError";
        this.errorInfo = errorDict;
    }

    getErrInfo() {
        return this.errorInfo;
    }
}