"use client"

import { ErrorBoundary } from '../../../components/error-boundary'
import { ClientErrorPage as ErrorFallback } from '../../../components/error'
import Settings from './components/settings'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../../../contexts/auth-context'

export default function Error({data, handler}) {
    const {username, setUsername, email, setEmail, role, setRole} = useContext(AuthContext)

    useEffect(() => {
        setUsername(data?.user?.username);
        setEmail(data?.user?.email);
        setRole(data?.user?.role);
    },[])

    return (
        <ErrorBoundary fallback={<ErrorFallback/>}>
            <Settings data={data} handler={handler}/>
        </ErrorBoundary>
    )
}