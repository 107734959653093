import { Card } from "react-bootstrap"

export function PercentageContainer({children, headerText, subHeaderText}) {
    return (
        <div className="container-fluid">
            <Card style={{borderWidth: '0px'}}>
                <Card.Body className="border-bottom-0">
                    <div className="d-flex flex-row">
                        <div className="d-flex w-sm-25 w-md-100 align-content-center me-md-0">
                            {children}
                        </div>
                        <div className="d-flex mt-4 justify-content-center align-items-center">
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}