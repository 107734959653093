import React, { useEffect, useState } from "react";
import { Form, Button } from 'react-bootstrap'
import NavLogo from "../../assets/Logo192.png";
import { validateContactUs } from "../../lib/validate/validate";
import { ModalForm } from "../modal-form";

import footerStyle from "../../styles/footer.module.css"; 

var CONTACT_INFO = {
    full_name: "",
    email: "",
    subject: "",
    msg_body: "",
}

export default function Footer() {
    const [showContactUs, setShowContactUs] = useState(false)
    const [contactInfo, setContactInfo] = useState(CONTACT_INFO);
    const [hadError, setHadError] = useState(false);
    const [contactInfoErrors, setContactInfoErrors] = useState({});

    useEffect(() => {
        if(!showContactUs) {
            setContactInfo({...CONTACT_INFO})
        }
    },[showContactUs])

    function updateContactInfo(contactInfo) {
        setContactInfo(prev => {
            return {...prev, ...contactInfo};
        })
    }

    async function submitContactInfo() {
        const errors = validateContactUs(contactInfo);

        if(!errors.msg_body && !errors.email) {
            setShowContactUs(false);
        } else {
            setHadError(true);
            setContactInfoErrors(errors);
        }
    }

    return (
        <div className={`${footerStyle.footer_web_wrapper} fs-6 position-fixed w-100 text-dark bottom-0 bg-light d-flex justify-content-center justify-self-center align-items-center`}>
            <ModalForm formType={"Contact Us"} toShow={showContactUs} toHide={setShowContactUs}
            footerText={""} hadError={hadError} errors={contactInfoErrors} setHadError={setHadError}
            setErrors={setContactInfoErrors}>
                <Form.Control className={'mt-4'} id='email-input' onChange={e => updateContactInfo({email: e.target.value})} value={contactInfo.email} placeholder='Email'/>
                <Form.Control className={'mt-3'} id='name-input' onChange={e => updateContactInfo({name: e.target.value})} value={contactInfo.username} placeholder='Full Name'/>
                <Form.Control className={'mt-3'} id='subject-input' onChange={e => updateContactInfo({subject: e.target.value})} value={contactInfo.subject} placeholder='Subject Line'/>
                <Form.Control className={"mt-3"} rows={5} as="textarea" id="msg_body-input" onChange={e => updateContactInfo({msg_body: e.target.value})} value={contactInfo.msg_body} placeholder="Message"/>
                <Button className={'mt-3 w-75'} onClick={() => submitContactInfo()} variant='outline-dark'>Send Message</Button>
            </ModalForm>
            <span className={"d-none d-sm-none d-md-inline"}>
            <>©2024 Polypinion Inc. All Rights Reserved</>
            <img src={NavLogo} className={`${footerStyle.footer_web_nav_logo_img} ms-2 me-2`} alt="Logo" />
            <a className={`text-dark`} href={`${process.env.REACT_APP_CLIENT_HOST}/terms-of-use`}> Terms of Use </a>
            <img src={NavLogo} className={`${footerStyle.footer_web_nav_logo_img} me-2 ms-2`} alt="Logo" />
            <a className={`text-dark`} href="#" onClick={() => setShowContactUs(true)}> Contact Us </a>
            </span>
            <span className={"d-inline d-sm-inline d-md-none"}>©2024 Polypinion Inc. All Rights Reserved</span>
        </div>
    );
};
