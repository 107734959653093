import { ExpandedArticleContainer } from "../../../components/container-article";

export default function ParseArticle({data}) {
  const structuredArticleData = {
      "@context": "https://schema.org",
      "@type": "Article",
      id: data?.article?.article_id,
      sortedarticle_id: data?.article?.sortedarticle_id,
      headline: data?.article?.title,
      articleBody: data?.article?.facts,
      text: data?.article?.opinions,
      image: data?.article?.article_image,
      datePublished: data?.article?.added_date,
      backstory: data?.article?.article_url,
      author: data?.article?.author?.toLowerCase() === "unknown" ? data?.article?.news_agency : data?.article?.author,        
      percentOpinion: data?.article?.percentage_opinion
  }

  return (
    <ExpandedArticleContainer article={structuredArticleData} similar_articles={data.similar_articles}/>
  );  
}
