"use client"

import React from "react";
import { ErrorBoundary } from '../../../components/error-boundary'
import { ClientErrorPage as ErrorFallback } from '../../../components/error'
import ExpandedArticle from "./components/expanded-article";

export default function Error({data}) {
    //use this site to test structured data: https://search.google.com/test/rich-results
    const structuredArticleData = {
        "@context": "https://schema.org",
        "@type": "Article",
        id: data?.article?.article_id,
        sortedarticle_id: data?.article?.sortedarticle_id,
        headline: data?.article?.title,
        articleBody: data?.article?.facts,
        text: data?.article?.opinions,
        image: data?.article?.article_image,
        datePublished: data?.article?.added_date,
        backstory: data?.article?.article_url,
        author: data?.article?.author?.toLowerCase() === "unknown" ? data?.article?.news_agency : data?.article?.author,
        url: `${process.env.REACT_APP_CLIENT_HOST}/news/${data?.article?.article_id}`,
        percentOpinion: data?.article?.percentage_opinion
    }
    
    return (
        <ErrorBoundary fallback={<ErrorFallback/>}>
            <script type="application/ld+json" dangerouslySetInnerHTML={{
                __html: JSON.stringify(structuredArticleData)
            }}/>
            <ExpandedArticle data={structuredArticleData} similar_articles={data.similar_articles}/>
        </ErrorBoundary>
    )
}