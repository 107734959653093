import { ServerError } from "../../components/server-error";

/**
 * a helper fetch method that provides error handling specific to our API, does not register error codes beyond
 * 500, 502, 400, or 401 as reported by the backend.
 * 
 * @param {string} url - a string representing the url to send requests to
 * @param {string} method - the HTTP method to send the request with
 * @param {string} headers - the HTTP headers to send alongside the request
 * @param {Map} [body=null] - optional, a request body
 * @param {string} [credentials=null] - optional, do you want to include or credentials, or not? This function does not support same-origin requests
 * @param {string} invoker - name of the function that invoked the fetcher, for error message logging purposes
 * @param {boolean} [returnJson=true] - optional, determines whether you receive a Response as a return value or a json object
 * @returns {(string|Promise)} a json string or promise depending on the `returnJson` parameter
 */
export async function fetcher({url, method, headers, body=null, credentials=null, invoker, returnJson=true}) {

    const options = {};
    const errors = {};

    options["method"] = method;
    options["headers"] = headers;
    if(body) options["body"] = body;
    if(credentials) options["credentials"] = 'include';

    const res = await fetch(url, options)
    .catch((err) => {
        console.error(`Failed to Fetch Error while fetching at the ${invoker} endpoint`);
        errors["hadError"] = true;
        errors["msg"] = err;
        errors["type"] = "failed to fetch";
    });

    if(errors.hadError) throw new ServerError(errors);

    if(res.status === 502) {
        console.error(`A server error was thrown while fetching at ${invoker} endpoint`);
        errors["hadError"] = true;
        errors["msg"] = `A server error was thrown while fetching at ${invoker} endpoint`;
        errors["type"] = "custom server error";
    } else if(res.status === 400) {
        console.error(`A client error was thrown while fetching at ${invoker} endpoint`);
        errors["hadError"] = true;
        errors["msg"] = `A client error was thrown while fetching at ${invoker} endpoint`;
        errors["type"] = "custom client error";
    } else if(res.status === 500) {
        console.error(`There was an internal server error while fetching at ${invoker} endpoint`);
        errors["hadError"] = true;
        errors["msg"] = `There was an internal server error while fetching at ${invoker} endpoint`;
        errors["type"] = "internal server error";
    } else if(res.status === 401) {
        console.error(`There was authorization error while fetching at ${invoker} endpoint`);
        errors["hadError"] = true;
        errors["msg"] = `There was an authorization error while fetching at ${invoker} endpoint`;
        errors["type"] = "unauthorized error";
    }

    if(errors.hadError) throw new ServerError(errors);

    if(returnJson) {
        return (await res.json());
    } else {
        return res;
    }
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=0';
}