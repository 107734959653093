import React from 'react';
import { GetTopTwentyArticles } from '../lib/fetch/fetch-articles';
import { LoadingScreen } from '../components/loading-screen';
import Error from './error';
import { ServerErrorPage as ErrorFallback } from '../components/error';
import { Helmet } from "react-helmet";

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            articles: null,
            isLoading: true,
            hadError: false,
            errors: null
        }
    }

    componentDidMount() {
        this.renderTopTwenty();
    }

    renderTopTwenty() {
        GetTopTwentyArticles()
        .then((response) => {
            this.setState({articles: response})
        })
        .then(() => {
            this.setState({isLoading: false});
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}})
        });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                <title>Polypinion - Make Better Decisions, Navigate Through Information Chaos</title>
                <meta name="description" content="Polypinion provides A.I.-powered, decluttered, and aggregated news, separating facts from opinions on politics, technology, war, and more, to help you make informed decisions." />
                <meta name="keywords" content="politics, technology, war, unbiased news, fact checking, news analysis, decluttered news, aggregated news" />
                <meta name="author" content="Polypinion" />
                <meta property="og:title" content="Polypinion - Make Better Decisions, Navigate Through Information Chaos" />
                <meta property="og:description" content="Polypinion provides A.I.-powered, decluttered, and aggregated news, separating facts from opinions on politics, technology, war, and more, to help you make informed decisions." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://polypinion.com" />
                </Helmet>
                {this.state?.hadError 
                ? <ErrorFallback errorDetails={this.state?.errors}/> 
                : <>{this.state?.isLoading 
                ? <LoadingScreen /> 
                : <Error data={this.state}/>}</>}
            </React.Fragment>
        )
    }
}