import React from 'react';
import { Alert } from 'react-bootstrap';

export function AlertValidation({errors, setHadError, setErrors}) {
    return (
        <Alert variant='danger' onClose={() => {setHadError(false); setErrors({})}} dismissible>
            <Alert.Heading className='navbar-invalid-input'>Invalid Input</Alert.Heading>
            <p className='navbar-invalid-input-text'>
                {Object.keys(errors).map((errorName, index) => (
                    <React.Fragment key={index}>
                        {errors[errorName]} <br/>
                    </React.Fragment>
                ))}
            </p>
        </Alert>
    )
}