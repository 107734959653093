import React, { useContext, useEffect } from "react";
import { ModalShare } from "./modal-share";
import { GenerateTinyURL } from '../lib/fetch/fetch-misc'
import defaultImage from "../assets/default_image.jpg";
import { useState } from "react";
import { ListGroup, Image, Button, Form } from "react-bootstrap";
import { FaShareAlt } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { NavbarContext } from "../contexts/navbar-context";
import { Chart as ChartJS, 
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend, 
    ArcElement} from 'chart.js';
import { Doughnut } from 'react-chartjs-2'
import { PercentageContainer as ChartCard } from "../pages/feed/expanded-article/components/PercentageContainer";

import containerStyle from '../styles/container-article.module.css'

const QRCode = require('qrcode')

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
)

async function shareArticle(item) {
    const qrcode = await QRCode.toDataURL(`${process.env.REACT_APP_CLIENT_HOST}/news/${item.id}`);

    let url = "";
    //Our TinyURL API rejects anything that doesn't have "www."
    //preceding the domain name
    if(window.location.href.slice(0, 5) === "https") {
        if(window.location.href.slice(8, 11) === "www") {
            url = "https://www." + process.env.REACT_APP_CLIENT_HOST.slice(8) + `/news/${item.id}`;
        } else {
            url = "https://www." + process.env.REACT_APP_CLIENT_HOST.slice(8) + `/news/${item.id}`;
        }
    } else {
        if(window.location.href.slice(7, 10) === "www") {
            url = "http://www." + process.env.REACT_APP_CLIENT_HOST.slice(7) + `/news/${item.id}`;
        } else {
            url = "http://www." + process.env.REACT_APP_CLIENT_HOST.slice(7) + `/news/${item.id}`;
        }
    }
    
    const res = (await GenerateTinyURL({url: url, sortedarticle_id: item.sortedarticle_id})).shorturl;

    return {
        shorturl: res,
        qrcode: qrcode
    };
}

export function ExpandedArticleContainer({article, similar_articles=null}) {  
    const [isSharing, setIsSharing] = useState(false);
    const [data, setData] = useState({});
    const [url, setUrl] = useState("");

    const { showSearchBar, setShowSearchBar } = useContext(NavbarContext)

    const doughnutLabel = {
        id: "doughnutLabel",
        afterDatasetsDraw(chart, args, plugin) {
            const { ctx, data } = chart;

            const centerX = chart.getDatasetMeta(0).data[0].x;
            const centerY = chart.getDatasetMeta(0).data[0].y;

            ctx.save();
            ctx.font = 'bold 20px sans-serif';
            ctx.fillStyle = 'orange';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`${Number(100 - article?.percentOpinion).toFixed(1)}%`, centerX, centerY);
        }
    }

    useEffect(() => {
        setShowSearchBar(true);
    },[])

    const navigate = useNavigate();

    async function shareHandler() {
        shareArticle(article)
        .then((res) => {
            setData(res);
        })
        .then(() => {
            setIsSharing(true);
        }) 
    }

    const handleSubmit = async () => {
        if (url) {
          window.location = `/target?url=${url}`;
        } else {
          console.error("Provide URL");
        }
      };
    
      const handleInputChange = (value) => {
        setUrl(value);
      };
      
    return (
        <div className={"container-fluid text-center"}>
            <div className="row">                        
                <div className="col-12 mt-3 mb-3 text-center d-grid d-sm-grid d-md-grid d-lg-none">
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-8'>
                                {showSearchBar 
                                ? 
                                <>
                                    <Form.Control value={url} className="h-100 fs-2" onChange={e => handleInputChange(e.target.value)}/>
                                </>
                                : <></>}
                            </div>
                            <div className='col-4'>
                                {showSearchBar 
                                ? <Button onClick={() => handleSubmit()} className={`h-100 fs-5 ${containerStyle.container_discover_button}`} size='sm' variant='outline-dark' type='submit'>
                                    Discover
                                </Button>   
                                : <></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 px-md-7">
                    <ModalShare isSharing={isSharing} setIsSharing={setIsSharing} QRSource={data.qrcode} shortLink={data.shorturl}/>
                    <div className={"mt-4 p-0 p-sm-0 p-md-5 row text-start mb-md-n6"}>
                        <div className={"col-12 col-md-12"}>
                            <h2><strong>{article?.headline}</strong></h2>
                        </div>
                        <div className={"col-12 col-sm-4 col-md-2"}>
                        <Button variant="outline-dark"  onClick={() => {shareHandler()}} className={"mt-3 w-100"}>
                            <FaShareAlt /> Share
                        </Button>
                        </div>
                    </div>
                    <div className="my-4 py-0 py-sm-0 py-md-5 d-flex justify-content-start w-100">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-8">
                                    {article?.image ? <Image className="w-75" src={article?.image} alt="article img" fluid /> : <></>}
                                </div>
                                <div className="col-12 col-sm-12 col-md-4">
                                    {!(isNaN(article?.percentOpinion)) ? <ChartCard headerText={"% Opinions vs Facts"} subHeaderText={"Facts v Opinion"}>
                                        <Doughnut options={
                                            {
                                            responsive: true, 
                                            maintainAspectRatio: true,
                                                plugins: {
                                                    legend: {
                                                        onHover: (evt, item, legend) => {
                                                        const chart = legend.chart;
                                                        const tooltip = chart.tooltip;

                                                        const chartArea = chart.chartArea;
                                                        tooltip.setActiveElements([{
                                                            datasetIndex: 0,
                                                            index: item.index,
                                                        }], {
                                                            x: (chartArea.left + chartArea.right) / 2,
                                                            y: (chartArea.top + chartArea.bottom) / 2,
                                                        });


                                                        chart.update();
                                                        },
                                                        position: 'right',
                                                    },
                                                    
                                                },
                                            }}
                                            plugins={[doughnutLabel]}
                                            data={{
                                            labels: ['Facts', 'Opinions'],
                                            datasets: [
                                                {
                                                    label: "Percent Facts vs Opinions",
                                                    data: [100 - Number(article?.percentOpinion), Number(article?.percentOpinion)],
                                                    borderColor: [
                                                        'black',
                                                        'black',
                                                    ],
                                                    backgroundColor: [
                                                        'orange',
                                                        'gray',
                                                    ],
                                                    borderWidth: 1
                                                }
                                            ]
                                        }}/>
                                    </ChartCard> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"mt-md-n6 mt-n2 p-0 p-sm-0 p-md-5 w-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 w-xxl-50 row text-start"}>
                        <h2>Facts:</h2>
                        {article?.articleBody?.split("- ").map((fact, index) => (
                        <React.Fragment key={index}>
                            {fact ? <p>{index === 0 ? index + 1 : index}. &#41; {fact}</p> : <></>}
                            <br/>
                        </React.Fragment>
                        ))}
                    </div>
                    <div className={"mt-3 p-0 p-sm-0 p-md-5 w-100 w-sm-100 w-md-50 w-lg-50 w-xl-50 w-xxl-50 row text-start"}>
                        <h2>Opinions:</h2>
                        {article?.text?.split("- ").map((opinion, index) => (
                        <React.Fragment key={index}>
                            {opinion ? <p>{index === 0 ? index + 1 : index}. &#41; {opinion}</p> : <></>}
                            <br/>
                        </React.Fragment>
                        ))}
                    </div>

                    <div className={"me-3 p-0 p-sm-0 p-md-5 text-start"}>
                        <Button variant="outline-dark" onClick={() => {shareHandler()}} className={`mt-3 w-xs-100 w-sm-20 w-md-15`} >
                            <FaShareAlt /> Share
                        </Button>
                    </div>
                    <div className={"mt-3 p-0 p-sm-0 p-md-5 row text-start"}>
                        <h2>Source:</h2>
                        <p><a href={article?.backstory}>{article?.backstory}</a></p>
                    </div>
                    <div className={"row mt-3 p-0 p-sm-0 p-md-5 text-start"}>
                        <h2>Author:</h2>
                        <p>{article?.author}</p>
                    </div>
                    <div className="row mt-3 p-0 p-sm-0 p-md-5 text-start">
                        {similar_articles && similar_articles[0]
                        ? <ListGroup>
                            <h2 className="ms-2">More Related</h2>
                            {similar_articles.map((simArticle, index) => (
                                <CompressedArticleContainer key={index} article={{
                                    title: simArticle.title,
                                    url: simArticle.url,
                                    image: simArticle.image,
                                    added_date: simArticle.publishedAt,
                                    source: simArticle?.source?.name,
                                    sourceUrl: simArticle?.source?.url
                                }} ancestor={"expanded_article"}/>
                            ))}
                          </ListGroup> 
                        : <></>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function CompressedArticleContainer({article, ancestor}) {
    const [isSharing, setIsSharing] = useState(false);
    const [data, setData] = useState({});

    async function shareHandler() {
        const structuredArticleData = {
            "@context": "https://schema.org",
            "@type": "Article",
            id: article?.article_id,
            sortedarticle_id: article?.sortedarticle_id,
            headline: article?.title,
            articleBody: article?.facts,
            text: article?.opinions,
            image: article?.article_image,
            datePublished: article?.added_date,
            backstory: article?.article_url,
            author: article?.author?.toLowerCase() === "unknown" ? article?.news_agency : article?.author,
            url: `${process.env.REACT_APP_CLIENT_HOST}/news/${data?.article?.article_id}`,
            percentOpinion: article?.percentage_opinion
        }
        shareArticle(structuredArticleData)
        .then((res) => {
            setData(res);
        })
        .then(() => {
            setIsSharing(true);
        }) 
    }

    function redirectUser() {
        if(ancestor==="expanded_article") {
            window.location = `${process.env.REACT_APP_CLIENT_HOST}/target?url=${article?.url}`;
        } else {
            window.location = `${process.env.REACT_APP_CLIENT_HOST}/news/${article?.article_id}`;
        }
    } 

    return (
        <ListGroup.Item className={`p-0 p-sm-0 mt-2 ${containerStyle.article_pointer}`} as="li" >
            <ModalShare isSharing={isSharing} setIsSharing={setIsSharing} QRSource={data.qrcode} shortLink={data.shorturl}/>
            <div  className={`container-fluid p-0 m-0 text-start w-100`} >
                <div className={'row'}>
                    <div onClick={() => redirectUser()} className={`${containerStyle.imageContainer} overflow-hidden col-12 col-md-3 col-sm-12 mb-2 d-sm-grid justify-content-center d-grid align-items-center`}>
                        {ancestor === "top_100" 
                        ? <Image className="" src={article.article_image || defaultImage} alt='Img...' fluid/> 
                        : ancestor === "expanded_article" 
                        ? <Image className="" src={article.image} alt="Img..." fluid/> 
                        : <Image className="" src={article.article_image} alt='' fluid/>}
                    </div>
                <div className={`col-12 col-sm-12 col-md-9 ms-1 me-1 ms-sm-1 me-sm-1 me-md-0 ms-md-0`}>
                    <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div onClick={() => redirectUser()} className={"col-12 col-sm-8"}>
                            <strong>{article?.title}</strong>
                        </div>
                        <div className={"col-12 col-sm-4 d-flex justify-content-center"}>
                        {ancestor !== "expanded_article" 
                        ? <>
                            <Button size="sm" variant="outline-dark"  onClick={() => {shareHandler()}} className={`align-self-center ms-1 w-100 mt-3 d-grid d-sm-none d-md-none`}>
                                    <div className={"container-fluid"}>
                                        <div className={"row"}>
                                            <div className={"col text-end"}>
                                                <FaShareAlt /> 
                                            </div>
                                            <div className={`col text-start ms-1 me-2`}>
                                                Share
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                                <Button size="sm" variant="outline-dark"  onClick={() => {shareHandler()}} className={`${containerStyle.shareButton} d-flex align-items-center mt-3 d-none d-sm-grid`}>
                                    <div className={"container "}>
                                        <div className={"row"}>
                                            <div className={"col text-end"}>
                                                <FaShareAlt /> 
                                            </div>
                                            <div className={`col-6 text-start me-2 `}>
                                                Share
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                                </>
                        : <>
                            <div className="my-4 my-sm-4 my-md-0 d-flex flex-column justify-content-center align-items-center">
                                <div><strong>{article?.source}</strong></div>
                                <div className="mt-3"><Image src={`https://s2.googleusercontent.com/s2/favicons?domain=${article?.sourceUrl}`} height={32} width={32} alt="" fluid/></div>
                            </div>
                        </>}
                        </div> 
                    </div>
                    <div onClick={() => redirectUser()} className={'row mt-3 ms-auto'}>
                        {article?.added_date?.substring(0, 26)}
                    </div>
                    </div>
                </div>
                <div className={"col-2 d-none d-sm-grid"}>
                </div>
                </div>
            </div>
        </ListGroup.Item>
    )
}
