import React from "react";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hadError: false};
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    //TODO: start passing the error info to the ClientErrorPage
    componentDidCatch(error, errorInfo) {
        this.setState({hadError: true});
    }

    render() {
        if(this.state.hadError) {
            return this.props.fallback;
        }
        
        return this.props.children;
    }
}