import React from "react";
import { useContext, useEffect } from 'react';
import {NavbarContext} from '../../../contexts/navbar-context'

export default function TermsOfUse({data}) {
    const {showSearchBar, setShowSearchBar} = useContext(NavbarContext)
  
    useEffect(() => {
      setShowSearchBar(false);
    },[])
    return (
        <div className="container text-start">
            <div className="row mt-3">
                <div className="col-md-2"></div>
                <div className="col-12 col-md-8">
                    <h1 className={`mt-3 text-center fw-bolder text-decoration-underline`}>Terms of Use</h1>
                    <p className="mt-3">
                        Welcome to Polypinion, an AI-powered platform for sharing and discovering information 
                        ("Polypinion" or the "Platform"). By accessing or using Polypinion, you agree to be 
                        bound by these Terms of Use and our Privacy Policy. If you do not agree with any part 
                        of these terms, you must not use the Platform.
                    </p>
                    <p className="mt-5 mb-5">  
                        1.) Use of the Platform The Platform is intended for your personal, non-commercial use only. 
                        You may not modify, copy, distribute, transmit, display, reproduce, publish, license, create 
                        derivative works from, transfer or sell any information, content, or services from the Platform.
                        <br/><br/>
                        2.) User Accounts To access certain features of the Platform, you may be required to create a user 
                        account. You are responsible for maintaining the confidentiality of your account credentials and for 
                        all activities that occur under your account. You agree to provide accurate and complete information 
                        when creating your account.
                        <br/><br/>
                        3.) User Content The Platform allows users to submit text, images, videos, and other materials ("User Content"). 
                        By submitting User Content, you grant Polypinion a perpetual, irrevocable, worldwide, royalty-free, and 
                        non-exclusive license to use, reproduce, modify, adapt, publish, translate, create derivative works from, 
                        distribute, and display your User Content in connection with the Platform.
                        <br/><br/>
                        4.) Content Sources and AI Processing Polypinion does not create original content. All content on the Platform 
                        is sourced from public domain sources and cited to the original source. Polypinion's AI technology processes and 
                        curates this content from various sources.
                        <br/><br/>
                        5.) User Data and Privacy Polypinion does not collect or sell users' private information or data.
                        to protecting user privacy and comply with all applicable data protection laws.
                        <br/><br/>
                        6.) Polypinion's mission is to combat information chaos and help users make better decisions by providing AI-curated 
                        and contextualized information from trusted sources.
                        <br/><br/>
                        7.) AI-Generated Content Polypinion uses artificial intelligence technology to generate information, insights, and 
                        recommendations ("AI-Generated Content"). The AI-Generated Content is provided for informational purposes only and 
                        should not be relied upon for any high-stakes decisions.
                        <br/><br/>
                        8.) Third-Party Content and Links The Platform may contain links to third-party websites or resources. Polypinion does 
                        not endorse or assume any responsibility for the content, accuracy, or policies of such third-party websites or resources.
                        <br/><br/>
                        9.) Intellectual Property Rights The Platform, including all content, features, and functionalities, is owned by Polypinion 
                        and is protected by copyright, trademark, and other intellectual property laws. You may not use any of Polypinion's trademarks 
                        or other intellectual property without prior written consent.
                        <br/><br/>
                        10.) Prohibited Activities You agree not to use the Platform for any unlawful purpose or in any way that could damage, disable, 
                        overburden, or impair the Platform or interfere with any other party's use and enjoyment of the Platform. This includes, but is 
                        not limited to, uploading or transmitting any viruses, malware, or other malicious code.
                        <br/><br/>
                        11.) Disclaimer of Warranties The Platform and all content and services provided through it are provided "as is" without warranty 
                        of any kind, express or implied. Polypinion does not guarantee the accuracy, completeness, or usefulness of any information on the 
                        Platform.
                        <br/><br/>
                        12.) Limitation of Liability In no event shall Polypinion or its affiliates, licensors, service providers, employees, agents, officers, 
                        or directors be liable for any direct, indirect, incidental, special, or consequential damages arising out of or related to your use of 
                        the Platform or any content or services provided through it.
                        <br/><br/>
                        13.) Indemnification You agree to indemnify, defend, and hold Polypinion and its affiliates, licensors, service providers, employees, agents, 
                        officers, and directors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out 
                        of your use of the Platform or violation of these Terms of Use.
                        <br/><br/>
                        14.) Governing Law These Terms of Use shall be governed by and construed in accordance with the laws of the State of Delaware, without giving 
                        effect to any principles of conflicts of law.
                        <br/><br/>
                        15.) Changes to Terms Polypinion reserves the right to modify or update these Terms of Use at any time without prior notice. Your continued use 
                        of the Platform after any such changes constitutes your acceptance of the new Terms of Use.
                        <br/><br/>
                        If you have any questions or concerns about these Terms of Use, please contact us at: help@polypinion.com
                        <br/><br/>
                        <p className="text-secondary">Last Updated - 04/20/24</p>
                    </p>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    )
}