import React from "react";
import Error from "./error";
import { ServerErrorPage as ErrorFallback } from "../../../components/error";
import { LoadingScreen as Loading } from '../../../components/loading-screen'
import { UserLevelLayout } from "../components/user-level-layout";
import { GetUserKeywords, IsSignedIn } from "../../../lib/fetch/fetch-users";

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hadError: false,
            isLoading: true,
            errors: null,
            user: null,
            keywords: null,
            reloadKeywords: false
        }

        this.handler = this.handler.bind(this);
    }

    handler() {
        this.setState({
            isLoading: true,
            reloadKeywords: true
        })
    }

    componentDidMount() {
        this.fetchSignIn()
        this.getKeywords()
        .then(() => {
            this.setState({isLoading: false})
        })
    }

    componentDidUpdate() {
        if(this.state.reloadKeywords) {
            this.getKeywords()
            .then(() => {
                this.setState({reloadKeywords: false})
                this.setState({isLoading: false})
            })
        }
    }

    async getKeywords() {
        await GetUserKeywords()
        .then((response) => {
            this.setState({keywords: response.keywords})
        })
    }

    async fetchSignIn() {
        await IsSignedIn()
        .then((response) => {
            this.setState({user: response.user?.user})
        })
        .catch((err) => {
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}});
        })
    }

    render() {
        return (
            <UserLevelLayout>
                {this.state.hadError
                ? <ErrorFallback errorDetails={this.state.errors}/>
                : this.state.isLoading
                ? <Loading/>
                : <Error data={this.state} handler={this.handler}/>}
            </UserLevelLayout>
        )
    }
}