import { fetcher, getCookie } from "./fetch-helper";

const server = process.env.REACT_APP_API_HOST;

export async function GetAdminDashboardMetrics() {
	"use server"
  
  	return (await fetcher({
		url: `${server}/api/metrics/get-dashboard-metrics`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-CSRF-TOKEN': getCookie("csrf_token")
		},
		credentials: 'include',
		invoker: 'get-dashboard-metrics'
  	}))
}

export async function GetTopHundredArticles() {
    "use server"

    return (await fetcher({
		url: `${server}/api/articles/get-top-hundred`, 
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		invoker: 'get-top-hundred'
    }))
}

export async function GetTopTwentyArticles() {
    "use server"

    return (await fetcher({
        url: `${server}/api/articles/get-top-twenty`, 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
		invoker: 'get-top-twenty'
    }))
}

export async function GetMostRecentArticle() {
	"use server"

	return (await fetcher({
		url: `${server}/api/articles/single-recent`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		invoker: 'single-recent'
	}))
}

export async function GetArticleByID({id}) {
    "use server"

	return (await fetcher({
		url: `${server}/api/articles/article-by-id?id=${id}`, 
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		invoker: 'article-by-id'
	}))
}

export async function AddNewArticle({urlParam}) {
    "use server"

	return (await fetcher({
		url: `${server}/api/articles/fetch-response`, 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			url: urlParam
		}),
		invoker: 'fetch-response'
	}))
}