import React from 'react';
import { GetTopHundredArticles } from '../../lib/fetch/fetch-articles'
import { LoadingScreen } from '../../components/loading-screen';
import Error from './error';
import { ServerErrorPage as ErrorFallback } from '../../components/error';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            articles: null,
            hadError: false,
            isLoading: true,
            errors: null
        }
    }

    componentDidMount() {
        this.renderTopHundred();
    }

    renderTopHundred() {
        GetTopHundredArticles()
        .then((response) => {
            this.setState({articles: response?.recent_articles});
        })
        .then(() => {
            this.setState({isLoading: false})
        })
        .catch((err) => {
            console.error(err)
            let errorInfo = err.getErrInfo();
            this.setState({hadError: true});
            this.setState({errors: {errorInfo}})
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.hadError 
                ? <ErrorFallback errorDetails={this.state.errors}/> 
                : <>{this.state.isLoading 
                ? <LoadingScreen/> 
                : <Error data={this.state}/>}</>}
            </React.Fragment>
        )
    }
}