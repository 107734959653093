import { ValidateUser } from "../fetch/fetch-users";

export async function validateSignIn(user) {
    const errors = {};

    if(!(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
    .test(user.email)) {
        errors.email = "Invalid email provided";
    }

    //dont bother fetching if the sign-in server request won't go through anyway due to preceding errors
    if(!errors.email) {
        const res = await ValidateUser({email: user.email, username: "NA"});
        let fetch_errors = res;

        if(fetch_errors.hadError) {
            errors.email = "User does not exist"
        }
    }

    return errors;
}

//TODO: make the city entry part of the form a dropdown from a api list of cities
export async function validateSubscription(subscriber) {
    const errors = {};

    if(!(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
    .test(subscriber.email)) {
        errors.email = "Invalid email provided";
    }

    //if the username length validator is every removed, remember that "NA" is
    //a reserved string to check if a username is not provided
    if(subscriber.username.length < 1 || subscriber.username.length > 30) {
        errors.username = "Username must be between 1-30 characters long";
    }

    if(!errors.email && !errors.username) {
        const res = await ValidateUser({email: subscriber.email, username: subscriber.username});
        let fetch_errors = res;

        if(fetch_errors.message) {
            errors.email = "The provided username/email is not available.";
        }
    }

    return errors;
}

export function validateContactUs(contacter) {
    const errors = {};

    if(!(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
    .test(contacter.email)) {
        errors.email = "Invalid email provided";
    }
    if(contacter.msg_body.length > 1500) {
        errors.msg_body = "Message must remain under 1500 characters";
    }

    return errors;
}