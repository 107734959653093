import React from "react";
import { Button, Image, ListGroup } from 'react-bootstrap'
import skinnyLogo from '../../assets/LogoTextNew.png';
import { MdSchedule as ScheduleIcon } from "react-icons/md";
import { CompressedArticleContainer } from '../../components/container-article';

import homeStyle from '../../styles/home.module.css'

export function Desktop({handleSubmit, url, handleInputChange, data}) {
    return (
        <div className='row'>
            <div className='col-2 col-sm-1'></div>
            <div className='col-8 col-sm-10'>
                <div className={`container-fluid ${homeStyle.home_countdown_img_pulse_wrapper} mt-5 w-50 d-flex flex-column align-items-center`}>
                    <Image className='home-background-img' src={skinnyLogo} alt='Logo' fluid/>
                    {/* <div className='home-light-element'></div> */}
                </div>
                <div className={`container-fluid ${homeStyle.home_search_mt_n} text-center`}>
                    <form className="" onSubmit={handleSubmit}>
                        <input
                        className={`w-50 ps-3 pe-2 bg-light border border-0 rounded ${homeStyle.home_search_query_input}`}
                        type='search'
                        name='home-search-query-input'
                        placeholder='Enter URL of the news page of your choice'
                        value={url}
                        onChange={handleInputChange}
                        />
                        <br/>
                        <Button className={`mt-2 w-50 rounded`} variant='outline-dark' type='submit'>
                            Discover Facts
                        </Button>
                    </form>
                </div>
            </div>
                <div className='mt-5 row'>
                    <div className='col-lg-2'></div>
                    <div className={`overflow-y-scroll col-12 col-lg-8 mb-5 text-center`}>
                        <h1 className={`mt-3 fw-bolder`}>
                        <ScheduleIcon fontSize='35' className='me-2'/> 
                        Recently Added
                        </h1>
                        <ListGroup as="ul">
                        {data?.articles?.recent_articles?.map((article, index) => ( 
                            <CompressedArticleContainer article={article} key={index} ancestor="top_twenty"/>
                        ))}
                        </ListGroup>
                </div>
            <div className='col-2 col-sm-1'></div>
            </div>
        </div>
    )
}

export function Mobile({handleSubmit, url, handleInputChange, data}) {
    return (
        <div className="row d-flex justify-content-center">
            <div className='col-12'>
                <div className={`container-fluid mt-5 ${homeStyle.home_countdown_img_pulse_wrapper} w-100 d-none d-sm-flex flex-column align-items-center`}>
                    <Image src={skinnyLogo} alt='Logo' fluid/>
                    {/* <div className='home-light-element'></div> */}
                </div>
                <div className={`container-fluid ${homeStyle.home_search_mt_n_mobile} text-center`}>
                    <form className="mt-5" onSubmit={handleSubmit}>
                        <input
                        className={`w-75 ps-3 pe-2 bg-light border border-0 rounded ${homeStyle.home_mobile_search_query_input}`}
                        type='search'
                        name='home-mobile-search-query-input'
                        placeholder='Enter URL of the news page of your choice'
                        value={url}
                        onChange={handleInputChange}
                        />
                        <br/>
                        <Button className={`mt-2 w-75 ${homeStyle.home_mobile_discover_button}`} variant='outline-dark' type='submit'>
                            Discover Facts
                        </Button>
                    </form>
                </div>
            </div>
            <div className='mt-5 row'>
                <div className='col-lg-2'></div>
                <div className={`overflow-y-scroll col-12 col-lg-8 mb-5 text-center`}>
                    <h1 className={`mt-3 ${homeStyle.home_recently_added_title}`}>
                    <ScheduleIcon fontSize='35' className='me-2'/> 
                    Recently Added
                    </h1>
                    <ListGroup as="ul">
                    {data?.articles?.recent_articles?.map((article, index) => ( 
                        <CompressedArticleContainer article={article} key={index} ancestor="top_twenty"/>
                    ))}
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}