import React, { useState } from "react";
import { CompressedArticleContainer } from "../../../components/container-article";
import { ListGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from 'react';
import {NavbarContext} from '../../../contexts/navbar-context'

import feedStyle from '../../../styles/feed.module.css'

export default function Feed({data}) {
  const [url, setUrl] = useState('');
  const {showSearchBar, setShowSearchBar} = useContext(NavbarContext)

  useEffect(() => {
    setShowSearchBar(false);
  },[])

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (url) {
      navigate(`/target?url=${url}`);
    } else {
      console.error("Provide URL");
    }
  };

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  return (
      <React.Fragment>
        {/*DESKTOP VERSION*/}
        <div className="container py-5 container-fluid d-none d-sm-none d-md-grid d-lg-grid d-xl-grid d-xxl-grid" >
              <div className="row mb-5">
              <div className="col-1"></div>
                <div className="col-10">
                  <div className="container-fluid text-center">
                        <form className="" onSubmit={handleSubmit}>
                            <input
                            className={`w-50 ps-3 pe-2 w-75 ps-3 pe-2 bg-light border border-0 rounded ${feedStyle.feed_search_query_input}`}
                            type='search'
                            name='home-search-query-input'
                            placeholder='Enter URL of the news page of your choice'
                            value={url}
                            onChange={handleInputChange}
                            />
                            <Button className={`mt-2 w-75`} variant='outline-dark' type='submit'>
                                Discover Facts
                            </Button>
                        </form>
                  </div>
                </div>
              <div className="col-1"></div>
              </div>
              <ListGroup as="ul">
                {data?.articles?.map((article, index) => (
                    <CompressedArticleContainer article={article} key={index} ancestor="top_100"/>
                ))}
              </ListGroup>
        </div>
        {/*MOBILE VERSION*/}
        <div className="container py-5 d-grid d-sm-grid d-md-none d-lg-none d-xl-none d-xxl-none" >
              <div className="row mb-5">
              <div className="col-1"></div>
                <div className="col-10">
                  <div className="container-fluid text-center">
                        <form className="" onSubmit={handleSubmit}>
                            <input
                            className={`ps-3 pe-2 w-100 ps-3 pe-2 bg-light border border-0 rounded ${feedStyle.feed_search_query_input}`}
                            type='search'
                            name='home-search-query-input'
                            placeholder='Enter URL of the news page of your choice'
                            value={url}
                            onChange={handleInputChange}
                            />
                            <Button className={`mt-2 w-100`} variant='outline-dark' type='submit'>
                                Discover Facts
                            </Button>
                        </form>
                  </div>
                </div>
              <div className="col-1"></div>
              </div>
          <ListGroup as="ul">
            {data?.articles?.map((article, index) => (
                <CompressedArticleContainer article={article} ancestor="top_100" key={index}/>
            ))}
          </ListGroup>
        </div>
      </React.Fragment>
  );
}
