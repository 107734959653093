import { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

export function CopyToClipboard({givenText, buttonType, buttonSize}) {
    const [show, setShow] = useState(false);

    async function writeToClipboard() {
        setShow(true);
        try {
            await navigator.clipboard.writeText(givenText);
        } catch(err) {
            console.error(err);
        }

        window.setTimeout(function() {
            setShow(false);
        }, 1000)
    }

    const tooltip = (
        <Tooltip>
            You succesfully copied this tinyURL!
        </Tooltip>
    )

    return (
        <div>
            <OverlayTrigger placement='bottom' overlay={tooltip} show={show}>
                <Button onClick={() => writeToClipboard()} variant={buttonType} size={buttonSize}>{givenText}</Button>
            </OverlayTrigger>
        </div>
    )
}