import { useContext, useEffect } from 'react';
import {NavbarContext} from '../../../contexts/navbar-context'

import dashboardStyle from "../../../styles/dashboard.module.css"

export default function UserDashboard({data}) {

    const {showSearchBar, setShowSearchBar} = useContext(NavbarContext)

    useEffect(() => {
        setShowSearchBar(false);
    },[])

    return (
        <div className="container-fluid">
        </div>
    )
}